import { Button, Input, InputNumber, message, Popover } from 'antd'
import R from 'assets'
import React from 'react'
import { handleExportExcelDKKD } from '../BusinessRegistrationAPI'

const ExportFromToPage = ({
  province,
  pickedNganhnghechinh,
  pickedLoaikhoan,
  pickedlinhVucKinhTe,
  page,
  searchQuery,
  mst,
  date,
  isActive,
  phone,
  totalrecord,
}: any) => {
  const [pageExport, setPageExport] = React.useState({
    from_page: 1,
    to_page: 2,
  })

  const totalPage = totalrecord ? Math.ceil(totalrecord / 12) : 0
  const caculateRecordFromToPage =
    (pageExport.to_page - pageExport.from_page + 1) * 12

  //export excel file - limit customer
  const [
    isLoadingButtonExportExcel,
    setIsLoadingButtonExportExcel,
  ] = React.useState<boolean>(false)

  const exportExcel = async () => {
    if (!pageExport.from_page) {
      message.error('Vui lòng nhập trang bắt đầu')
      return
    }

    if (!pageExport.to_page) {
      message.error('Vui lòng nhập trang kết thúc')
      return
    }

    if (pageExport.from_page > pageExport.to_page) {
      message.error('Trang bắt đầu phải nhỏ hơn hoặc bằng trang kết thúc')
      return
    }

    try {
      const query = JSON.stringify({
        province_name: province,
        enterprise_type: pickedNganhnghechinh,
        loai_khoan: pickedLoaikhoan,
        linh_vuc: pickedlinhVucKinhTe,
      })
      const payload: any = {
        page: page,
        search: searchQuery,
        mst: mst,
        date: date,
        query,
        isActive,
        phone,
      }

      setIsLoadingButtonExportExcel(true)
      message.success(
        R.strings().message_request_has_been_received +
          ' ' +
          R.strings().message_please_wait_a_few_minutes
      )
      const response = await handleExportExcelDKKD(payload, {
        from_page: pageExport.from_page,
        to_page: pageExport.to_page,
      })
      saveAs(response.data, `Du_lieu_DKKD.xlsx`)
    } catch (error) {
      console.log('Error - Export excel file - DKKD: ', { error })
      message.error(
        R.strings().message_an_error_occurred +
          ' ' +
          R.strings().message_please_try_again
      )
    } finally {
      setIsLoadingButtonExportExcel(false)
    }
  }

  return totalrecord > 0 ? (
    <Popover
      trigger="click"
      title="Xuất excel theo phân trang"
      content={
        <div>
          <Input.Group compact>
            <InputNumber
              onChange={from_page => {
                setPageExport({
                  ...pageExport,
                  from_page,
                })
              }}
              value={pageExport.from_page}
              min={1}
              step={1}
              style={{ width: 100, textAlign: 'center' }}
              placeholder="Từ trang"
              type="number"
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />
            <Input
              className="site-input-split"
              style={{
                width: 30,
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: 'none',
              }}
              placeholder="~"
              disabled
            />
            <InputNumber
              value={pageExport.to_page}
              onChange={to_page => {
                setPageExport({
                  ...pageExport,
                  to_page,
                })
              }}
              max={totalPage}
              step={1}
              style={{
                width: 100,
                textAlign: 'center',
              }}
              placeholder="Đến trang"
              type="number"
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />
          </Input.Group>
          <div
            style={{
              color: 'red',
              fontSize: '12px',
              fontStyle: 'italic',
              margin: '5px 0',
            }}
          >
            Export tối đa 2000 bản ghi
          </div>
          <Button
            loading={isLoadingButtonExportExcel}
            onClick={exportExcel}
            style={{ width: '100%' }}
            type="primary"
          >
            Export {caculateRecordFromToPage && `(${caculateRecordFromToPage})`}
          </Button>
        </div>
      }
    >
      <Button
        type="primary"
        style={{
          marginLeft: '5px',
          backgroundColor: 'green',
          border: 'none',
          color: 'white',
        }}
        disabled={isLoadingButtonExportExcel}
        // onClick={exportExcel}
      >
        {R.strings().action_export_excel_file} phân trang
      </Button>
    </Popover>
  ) : null
}

export default ExportFromToPage
